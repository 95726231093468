
import moment from 'moment'
import Vue, { PropType } from 'vue'

import Breadcrumbs from '../../components/Breadcrumbs.vue'

import SpinnerLoader from '../../components/SpinnerLoader.vue'
import { checkRole } from '../../helpers/roles'
import { Connector, Project } from '../../types/main'
import { CONNECTOR_TYPES } from '../../vars/general'
import NotFound from '../NotFound.vue'
import KpiDashboard from './subViews/KpiDashboard.vue'
import KpiSettings from './subViews/KpiSettings.vue'
export default Vue.extend({
    name: 'GCB2Kpi',
    components: {
        Breadcrumbs,
        SpinnerLoader,
        NotFound,
        KpiDashboard,
    },

    data: () => ({
        activeTabNum: null as number | null | undefined,
    }),
    watch: {
        activeTabNum(currentTabNum, prevTabNum) {
            let tab = this.tabs.find(el => el.tabNum === this.activeTabNum)
            if (tab?.disabled) {
                return
            }
            const sectionName = tab?.sectionName
            this.$router.push({
                path: this.$router.currentRoute.path,
                query: Object.assign({}, this.$router.currentRoute.query, { section: sectionName }),
            })
        },
        '$route.query.section'() {
            const sectionName = this.$router.currentRoute.query.section
            const tab = this.tabs.find(el => el.sectionName === sectionName)
            if (tab?.disabled) {
                return
            }
            const tabNum = tab?.tabNum
            this.activeTabNum = tabNum
        },
    },
    computed: {
        tabs(): any[] {
            return [
                {
                    tabName: 'РАСЧЁТ KPI',
                    tabNum: 0,
                    sectionName: 'dashboard',
                    component: KpiDashboard,
                    disabled: !this.checkRole(this.project.id, 'gcb2:kpi:view'),
                },
                {
                    tabName: 'СХЕМЫ РАСЧЁТА',
                    tabNum: 1,
                    sectionName: 'settings',
                    component: KpiSettings,
                    disabled: !this.checkRole(this.project.id, 'gcb2:kpi:edit'),
                },
            ]
        },
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: 'HR платформа для Idol Face',
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'KPI сотрудников',
                    to: '',
                    disabled: true,
                },
            ]
        },
        tabsNames(): string[] {
            return this.tabs.map(el => el.tabName)
        },
    },
    methods: { checkRole },
    async mounted() {},
    async created() {
        const section = this.$router.currentRoute.query.section
        if (section) {
            const tab = this.tabs.find(el => el.sectionName === section)
            if (tab?.disabled) {
                return
            }
            const tabNum = tab?.tabNum
            this.activeTabNum = tabNum
        }
    },
})
